const baseColors = {
  primary: {
    main: "var(--primary-orange)",
    light: "var(--secondery-orange)",
  },
  secondery: {
    main: "var(--neutral-30p)",
    light: "var(--neutral-20p)",
  },
  error: {
    main: "var(--red)",
    light: "var(--red-shade)",
  },
  warning: {
    main: "var(--orange)",
    light: "var(--orange-shade)",
  },
  info: {
    main: "var(--blue)",
    light: "var(--blue-shade)",
  },
  success: {
    main: "var(--green)",
    light: "var(--green-shade)",
  },
  pink: {
    main: "var(--pink-company)",
    light: "var(--pink-company)",
  },
};

export const palette = {
  ...baseColors,
  background: {
    main: "var(--background-light)",
    light: "var(--neutral-10p)",
    dark: "var(--background-dark)",
    grey: "var(--neutral-20p)",
  },
  typography: {
    primary: "var(--neutral-50p)",
    secondery: "var(--neutral-40p)",
    info: "var(--primary-orange)",
    light: "var(--neutral-10p)",
    onDark: "var(--neutral-50p-dark)",
    error: "var(--red-shade)",
    pink: "var(--pink-company)",
  },
  buttons: {
    background: {
      primary: "var(--primary-pink)",
      secondery: "var(--neutral-30p)",
      info: "var(--neutral-30p)",
      disabled: "var(--neutral-20p)",
    },
    hover: {
      primary: "var(--primary-pink)",
      secondery: "var(--neutral-20p)",
      info: "var(--neutral-20p)",
      disabled: "var(--neutral-20p)",
    },
    typography: {
      Primary: "var(--neutral-10p)",
      secondery: "var(--neutral-10p)",
      info: "var(--primary-orange)",
      disabled: "var(--neutral-40p)",
    },
  },
  inputs: {
    background: {
      contained: "var(--neutral-20p)",
      underline: "transparent",
    },
    typography: {
      contained: "var(--neutral-50p)",
      underline: "var(--neutral-50p-dark)",
    },
    border: {
      contained: "transparent",
      underline: "var(--primary-orange)",
    },
  },
};

export const fonts = {
  size: {
    smallest: "14px",
    smaller: "24px",
    small: "30px",
    medium: "40px",
    large: "60px",
  },
  fontFamily: {
    ragular: "Assistant-Regular",
    bold: "Assistant-Regular",
  },
};

export const typography = {
  varient: {
    h1: {
      fontSize: fonts.size.large,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.bold,
      fontWeight: 700,
    },
    h2: {
      fontSize: fonts.size.medium,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.bold,
      fontWeight: 700,
    },
    h3: {
      fontSize: fonts.size.small,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.bold,
      fontWeight: 700,
    },
    h4: {
      fontSize: fonts.size.smaller,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.ragular,
      fontWeight: 500,
    },
    mainText: {
      fontSize: fonts.size.small,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.ragular,
      fontWeight: 400,
    },
    body2: {
      fontSize: "26px",
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.ragular,
      fontWeight: 400,
    },
    body3: {
      fontSize: fonts.size.smallest,
      color: palette.typography.pink,
      fontFamily: fonts.fontFamily.ragular,
      fontWeight: 400,
    },
    button: {
      fontSize: fonts.size.small,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.bold,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "16px",
      color: palette.typography.error,
      fontFamily: fonts.fontFamily.ragular,
      fontWeight: 400,
    },
  },
};

const buttons = {
  size: {
    small: "64px",
    large: "104px",
  },
  varient: {
    primary: {
      backgroundColor: palette.buttons.background.primary,
      color: palette.buttons.typography.Primary,
      fontSize: typography.varient.button.fontSize,
      fontFamily: fonts.fontFamily.bold,
      fontWeight: 700,
    },
    secondery: {
      backgroundColor: palette.buttons.background.secondery,
      color: palette.buttons.typography.secondery,
      fontSize: typography.varient.button.fontSize,
      fontFamily: fonts.fontFamily.bold,
      fontWeight: 700,
    },
    info: {
      backgroundColor: palette.buttons.background.info,
      color: palette.buttons.typography.info,
      fontSize: typography.varient.button.fontSize,
      fontFamily: fonts.fontFamily.ragular,
      fontWeight: 400,
    },
  },
};

const inputs = {
  varient: {
    contained: {
      backgroundColor: palette.inputs.background.contained,
      borderBottomColor: palette.inputs.border.contained,
      color: palette.typography.primary,
      fontFamily: fonts.fontFamily.ragular,
      placeholder: palette.typography.secondery,
    },
    underline: {
      backgroundColor: palette.inputs.background.underline,
      borderBottomColor: palette.inputs.border.underline,
      color: palette.typography.onDark,
      fontFamily: fonts.fontFamily.ragular,
      placeholder: palette.typography.onDark,
    },
  },
};

const Theme = {
  palette: palette,
  fonts: fonts,
  typography: typography,
  buttons: buttons,
  inputs: inputs,
};

export type ThemeType = typeof Theme;

export default Theme;
