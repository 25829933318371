import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";

import Routes from "../../routes";
import BonusPage from "../bonusPage/BonusPage";
import EditBonus from "../editBonus/EditBonus";
import Home from "../home/Home";
import Login from "../login/Login";

const NavigationSwitch = () => {
  return (
    <Switch>
      <Route path={Routes.Dashboard.home} element={<Home />} />
      <Route path={Routes.Auth.login} element={<Login />} />
      <Route path={Routes.Dashboard.bonus} element={<BonusPage />} />
      <Route path={Routes.Dashboard.editBonus} element={<EditBonus />} />
      <Route path="*" element={<Navigate to={Routes.Dashboard.home} replace />} />
    </Switch>
  );
};

export default NavigationSwitch;
