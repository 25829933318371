import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBonus } from "../../../interfaces/models/bonus";
import { IBonusState } from "../../../interfaces/store/bonus-interface";
import { IBonusDetails } from "../../../interfaces/store/viewing-bonus-interface";

const initialState: IBonusDetails = {
  bonusDetails: null,
  error: null,
  loading: true,
  bonusCreated: false,
};

const _getBonusById = (state: IBonusDetails, payload: PayloadAction<{ bonusId: string | undefined }>) => {
  state.loading = true;
  state.error = null;
  state.bonusCreated = false;
};

const _fetchByIdSuccess = (state: IBonusDetails, { payload }: PayloadAction<IBonus>) => {
  state.bonusDetails = payload;
  state.error = null;
  state.loading = false;
};

const _fetchByIdFail = (state: IBonusDetails, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusDetails = null;
  state.error = payload;
  state.loading = false;
};

const _updateFields = (state: IBonusDetails, { payload }: PayloadAction<IBonus>) => {
  state.bonusDetails = payload;
  state.error = null;
  state.loading = false;
  state.bonusCreated = false;
};

const _updateBonus = (state: IBonusDetails, { payload }: PayloadAction<IBonus>) => {
  state.loading = true;
  state.error = null;
  state.bonusCreated = false;
};

const _initNewBonus = (state: IBonusDetails) => {
  state.bonusDetails = {
    title: "",
    image: "",
    logo: "",
    shortDescription: "",
    description: [""],
    webSite: "",
    sponsorName: "",
    evrCurrency: "",
    priceEvrCurrency: 0,
    price: 0,
    currency: "",
    available: 0,
  };
  state.loading = false;
  state.error = null;
};

const _createBonus = (state: IBonusDetails, { payload }: PayloadAction<IBonus>) => {
  state.loading = true;
  state.error = null;
};

const _createBonusSuccess = (state: IBonusDetails, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusDetails = null;
  state.bonusCreated = true;
  state.error = payload;
  state.loading = false;
};

const _createBonusFail = (state: IBonusDetails, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusDetails = null;
  state.error = payload;
  state.loading = false;
  state.bonusCreated = false;
};

const _removeEditor = (state: IBonusDetails, { payload }: PayloadAction<{ index: number }>) => {
  state.bonusDetails?.description?.splice(payload.index, 1);
  state.loading = false;
  state.error = null;
};

const _toggleBonusCreated = (state: IBonusDetails) => {
  state.loading = true;
  state.error = null;
  state.bonusCreated = false;
};

const bonusDetailsSlice = createSlice({
  name: "bonusDetails",
  initialState,
  reducers: {
    fetchByIdSuccess: _fetchByIdSuccess,
    fetchByIdFail: _fetchByIdFail,
    getBonusById: _getBonusById,
    updateFields: _updateFields,
    updateBonus: _updateBonus,
    initNewBonus: _initNewBonus,
    createBonus: _createBonus,
    removeEditor: _removeEditor,
    createBonusFail: _createBonusFail,
    createBonusSuccess: _createBonusSuccess,
    toggleBonusCreated: _toggleBonusCreated,
  },
});

const { actions, reducer } = bonusDetailsSlice;
export const { fetchByIdSuccess, fetchByIdFail, getBonusById, createBonus, initNewBonus, updateFields, updateBonus, removeEditor, createBonusFail, createBonusSuccess, toggleBonusCreated } = actions;

export default reducer;
