import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import "./styles/index.css";
import App from "./app/App";
import store from "./store/config";
import Theme from "./theme/theme";
import "./i18n/i18next";
import Spinner from "./components/ui/Spinner";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={Theme}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </Provider>
  </ThemeProvider>
);
