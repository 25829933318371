import styled from "styled-components";

export const ReactTable = styled.table`
  border: 1px solid lightgray;
  margin: auto;
  thead {
    background-color: lightgray;
  }
  tbody tr:hover {
    background-color: lightcyan;
  }

  width: 100%;
`;
export const TableBody = styled.tbody`
  border-bottom: 1px solid lightgray;
`;

export const TableHeader = styled.th`
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
  width: 100%;
`;

export const TableHeaderCell = styled.th`
  border: 1px solid lightgray;
`;

export const TableFoot = styled.tfoot`
  color: gray;
  th {
    font-weight: normal;
  }
`;

export const TableRow = styled.tr`
  height: 200px;
  td {
    /* height: 300px; */
  }
`;

export const TableCell = styled.td`
  border: 0.5px solid lightgray;
  padding: 10px;
`;
export const CheckBoxCont = styled.div`
  display: flex;
  padding: 20px;
  text-align: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  div {
    border: 2px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
  }
`;
