import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

import { ContentState, convertToRaw, EditorState } from "draft-js";

import { EditorCont } from "./editorInstanceStyle";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { IEditorInstanceProps } from "../../../interfaces/components/editor/editorInstance";
import { removeEditor } from "../../../store/features/bonusDetails/bonusDetails-slice";
import { FlexRowSpacedAligned, NavButton } from "../../home/HomeStyles";
import { FlexRowSpaced } from "../../common/containers";

const EditorInstance: React.FC<IEditorInstanceProps> = ({ value, state, setState, index }) => {
  const dispatch = useAppDispatch();
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const _editorState = EditorState.createWithContent(contentState);
    setEditorState(_editorState);
  }, []);

  const handleItemDelete = () => {
    if (state.description!.length > 1) dispatch(removeEditor({ index }));
  };

  return (
    <EditorCont>
      <FlexRowSpacedAligned>
        Section {index + 1}
        <NavButton onClick={handleItemDelete}>Delete</NavButton>
      </FlexRowSpacedAligned>
      <Editor
        editorState={editorState}
        wrapperClassName="card"
        placeholder="Begin typing..."
        editorClassName="card-body"
        onEditorStateChange={(newState) => {
          setEditorState(newState);

          const newDescritionArray: string[] = [...state.description!];
          newDescritionArray![index] = draftToHtml(convertToRaw(newState.getCurrentContent()));
          setState((prev) => {
            return { ...prev, description: newDescritionArray };
          });
        }}
        toolbar={{
          options: ["inline", "list", "history", "emoji", "textAlign"],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </EditorCont>
  );
};
export default EditorInstance;
