const dev = {
  apiGateway: {
    API_SERVER_URL: "http://localhost:8080/",
    IMAGE_STORAGE_URL: "",
  },
};

const int = {
  apiGateway: {
    API_SERVER_URL: "https://devapi-bonus.evrgaming.com/",
    IMAGE_STORAGE_URL: "",
  },
};

const prod = {
  apiGateway: {
    API_SERVER_URL: "https://evrcoupons.ivtech.co.il/",
    IMAGE_STORAGE_URL: "",
  },
};

const apiKeys = {
  GOOGLE_CLIENT_ID: "14805010597-nt4i8fqcaigfahocpl6lkm5uus3899al.apps.googleusercontent.com",
  FACEBOOK_CLIENT_ID: "489765169162454",
  APPLE_CLIENT_ID: "",
};

let config = int;
if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim() === 'production') {
    config = prod;
}
else if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim() === 'int') {
    config = int;
}

let exportConfig = {
  APP_VERSION: 0.1,
  ...config,
  apiKeys,
};
export default exportConfig;
