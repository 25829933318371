import styled from "styled-components";
import { IBgProps, ITextProps } from "../../interfaces/components/login/login";

import { FlexColumn, FlexRowCentered } from "../common/containers";
import { Typography } from "../ui/Typography";

export const LoginPageLayout = styled(FlexRowCentered)`
  background-color: var(--background-dark);
  height: inherit;
  justify-content: space-around;
`;

export const StyledBreakLine = styled.div`
  border-top: 1px #4cb098 solid;
  text-align: center;
  padding: 35px;
`;

export const Container = styled(FlexColumn)`
  display: flex;
  justify-content: space-between;
  min-width: 377px;
  max-width: 25%;
  min-height: 530px;
  margin-top: 70px;
`;

export const Spinnercontainer = styled.div`
  align-self: center;
  height: 50px;
`;

export const Buttoncontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 50px auto;
`;

export const TitleContainer = styled(FlexRowCentered)`
  justify-content: space-between;
`;

export const LoginWith = styled.div<IBgProps>`
  border-radius: 14px;
  height: 56px;
  background-color: ${(props) => props.variant};
  color: ${(props) =>
    props.variant === "var(--login-google)"
      ? "var(--login-google-text)"
      : "var(--login-faceboook-text)"};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 300px;
  margin: 20px auto;
  @media screen and (min-width: 400px) {
    width: 362px;
  }
`;

export const StyledLoginText = styled.p<ITextProps>`
  pointer-events: none;
  cursor: "pointer";
  color: ${(props) => (props.white ? "#9E9E9E" : "#FFFFFF")};
  font-size: 16px;
  font-weight: 400;
`;
