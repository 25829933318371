import axiosInstance, { updateToken2fa } from "../../config/axios";
import { IBonus } from "../../interfaces/models/bonus";
import ServerRoutes from "./routes";
import { handleAxiosError } from "./utils/common";

const getAllBonuses = async (page: number, limit: number) => {
  try {
    const { status, data } = await axiosInstance.get(ServerRoutes.bonus.getAll, { headers: { page, limit } });
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message = "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const getAllBonusesNoPagination = async () => {
  try {
    const { status, data } = await axiosInstance.get(ServerRoutes.bonus.getAllNoPagination);

    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message = "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const getBonusById = async (bonusId: string) => {
  try {
    const { status, data } = await axiosInstance.get(ServerRoutes.bonus.getWithId + bonusId);
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message = "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const updateBonusById = async (bonusObject: { bonus: IBonus }) => {
  try {
    const { status, data } = await axiosInstance.put(ServerRoutes.bonus.updateBonus, bonusObject);
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message = "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const createBonus = async (bonusObject: { bonus: IBonus }) => {
  try {
    const { status, data } = await axiosInstance.post(ServerRoutes.bonus.createBonus, bonusObject);
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message = "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const deleteBonus = async (id: string) => {
  try {
    const { status, data } = await axiosInstance.delete(ServerRoutes.bonus.deleteBonus + id);
    return { status, data };
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message = "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 404:
        message = "Bonus not found";
        break;
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const BonusApi = {
  getAllBonuses,
  getBonusById,
  getAllBonusesNoPagination,
  updateBonusById,
  createBonus,
  deleteBonus,
};

export default BonusApi;
