import createSagaMiddleware from "redux-saga";
import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducer";
import authUserSaga from "./features/auth-user/auth-user-saga";
import bonusSaga from "./features/bonus/bonus-saga";
import bonusDetailsSaga from "./features/bonusDetails/bonusDetails-saga";

const saga = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: [saga],
});
saga.run(bonusSaga);
saga.run(authUserSaga);
saga.run(bonusDetailsSaga);

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
