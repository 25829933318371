import AuthUserSlice from "./auth-user/auth-user-slice";
import BonusSlice from "./bonus/bonus-slice";
import bonusDetailsSlice from "./bonusDetails/bonusDetails-slice";

const reducers = {
  auth: AuthUserSlice,
  bonus: BonusSlice,
  bonusDetails: bonusDetailsSlice,
};

export default reducers;
