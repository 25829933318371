import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import MainAppLayout from "../components/hoc/MainAppLayout";
import NavigationSwitch from "../components/navigation/NavigationSwitch";

function App() {
  return (
    <Router>
      <MainAppLayout>
        <NavigationSwitch />
      </MainAppLayout>
    </Router>
  );
}

export default App;
