import styled from "styled-components";

const LayoutContainer = styled.div`
  width: 100%;
`;

const PageLayout = ({ children }: { children: any }) => {
  return <LayoutContainer>{children}</LayoutContainer>;
};

export default PageLayout;
