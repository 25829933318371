export const AuthRoutes = {
  login: "/login",
  logout: "/logout",
};

export const DashboardRoutes = {
  home: "/home",
  editBonus: "/edit/:id",
  bonus: "/bonus/:id",
};

const Routes = {
  Auth: AuthRoutes,
  Dashboard: DashboardRoutes,
};

export default Routes;
