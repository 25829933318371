import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import BonusApi from "../../api/bonus-api";
import { fetchFail, loadBonuses as loadBonuses, fetchPageSuccess, deleteBonusSuccess, deleteBonus, deleteBonusError } from "./bonus-slice";

function* _loadAllBonuses(action: PayloadAction<{ page: number; RESULTS_PER_LOAD_LIMIT: number }>): any {
  try {
    const { data, status, error } = yield call(BonusApi.getAllBonusesNoPagination);

    if (error) {
      yield put(fetchFail(error));
    }
    if (status === 200) {
      yield put(fetchPageSuccess({ bonus: data }));
    }
  } catch (err: any) {
    return yield put(fetchFail(err));
  }
}

function* _deleteBonus(action: PayloadAction<{ id: string; index: number }>): any {
  const { id, index } = action.payload;

  const { data, status, error } = yield call(BonusApi.deleteBonus, id);

  try {
    if (error) {
      yield put(deleteBonusError(error));
    }
    if (status === 200) {
      yield put(deleteBonusSuccess({ index }));
    }
  } catch (err: any) {
    return yield put(deleteBonusError(err));
  }
}
function* bonusSaga() {
  yield takeLatest(loadBonuses.type, _loadAllBonuses);
  yield takeLatest(deleteBonus.type, _deleteBonus);
}

export default bonusSaga;
