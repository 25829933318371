import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CombinedState } from "@reduxjs/toolkit";

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { createBonus, getBonusById, initNewBonus, updateBonus, updateFields } from "../../store/features/bonusDetails/bonusDetails-slice";
import Card from "../home/card/Card";
import { BonusDescriptionPreviewCont, BonusParagraph, BonusPreview, BonusPreviewCont, EditBonusCont, EditBonusPageContainer, InputField } from "./editBonusStyles";
import { IBonusDetails } from "../../interfaces/store/viewing-bonus-interface";
import { IBonus } from "../../interfaces/models/bonus";
import { IAuthUserState } from "../../interfaces/store/auth-user-interfaces";
import { IBonusState } from "../../interfaces/store/bonus-interface";
import EditorInstance from "./editorInstance/EditorInstance";
import { Typography } from "../ui/Typography";

import { ReactComponent as EVRIcon } from "../../assets/icons/nav-evr.svg";
import { FlexRowSpacedAligned, NavButton, NavCont } from "../home/HomeStyles";
import { VanillaSpinner } from "../ui/Spinner";
import sanitizeHtml from "sanitize-html";
import { FlexRowSpaced } from "../common/containers";

let initialState: IBonus | undefined = undefined;

export default function EditBonus() {
  const bonusDetailsState = useAppSelector(
    (
      state: CombinedState<{
        auth: IAuthUserState;
        bonus: IBonusState;
        bonusDetails: IBonusDetails;
      }>
    ) => state.bonusDetails
  );
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [inputState, setInputState] = useState<IBonus>({
    _id: "",
    title: "",
    image: "",
    logo: "",
    shortDescription: "",
    description: [""],
    webSite: "",
    sponsorName: "",
    evrCurrency: "",
    priceEvrCurrency: 0,
    price: 0,
    codeCoupon: "",
    currency: "",
    available: 0,
  });
  const [message, setMessage] = useState<string>(params.id === "new" ? "Create new bonus" : `Editing bonus`);

  useEffect(() => {
    if (params.id === "new") {
      dispatch(initNewBonus());
    } else {
      dispatch(getBonusById({ bonusId: params.id }));
    }
  }, []);

  useEffect(() => {
    if (bonusDetailsState.bonusDetails) {
      setInputState(bonusDetailsState.bonusDetails);
      if (initialState === undefined) initialState = bonusDetailsState.bonusDetails;
    }
    if (bonusDetailsState.bonusCreated) {
      navigate("/home");
    }
  }, [bonusDetailsState]);

  const handleChange = (e: any) => {
    setInputState({ ...inputState, [e.target.id]: e.target.value });
  };

  const handleAddNewSection = () => {
    setInputState((prev) => {
      const newState = { ...prev };
      newState.description = [...newState.description!, ""];
      dispatch(updateFields(newState));
      return newState;
    });
  };

  const handleSaveClick = () => {
    if (checkValidity()) {
      if (params.id === "new") {
        dispatch(createBonus(inputState));
      } else {
        dispatch(updateBonus(inputState));
      }
    } else {
      setMessage("Please fill out all the fields");
    }
  };

  const checkValidity = () => {
    return (
      inputState.available! >= 0 &&
      inputState.currency!.length > 0 &&
      inputState.description!.length > 0 &&
      inputState.evrCurrency!.length > 0 &&
      inputState.image!.length > 0 &&
      inputState.logo!.length > 0 &&
      inputState.price! > 0 &&
      inputState.priceEvrCurrency! > 0 &&
      inputState.shortDescription!.length > 0 &&
      inputState.sponsorName!.length > 0 &&
      inputState.title!.length > 0 &&
      inputState.webSite!.length > 0
    );
  };

  const handleResetClick = () => {
    setInputState(initialState!);
  };

  return (
    <>
      <NavCont>
        <EVRIcon
          onClick={() => {
            navigate("/home");
          }}
        />
        <Typography varient="h2">{message}</Typography>
      </NavCont>
      <EditBonusPageContainer>
        <EditBonusCont>
          {!bonusDetailsState.loading && !bonusDetailsState.error ? (
            <>
              <InputField>
                <label htmlFor="title">Title:</label>
                <input type="text" id="title" value={inputState.title} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="shortDescription">short desc</label>
                <input type="text" id="shortDescription" value={inputState.shortDescription} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="logo">sponsor name</label>
                <input type="text" id="sponsorName" value={inputState.sponsorName} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="logo">sponsor logo</label>
                <input type="text" id="logo" value={inputState.logo} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="image">image</label>
                <input type="text" id="image" value={inputState.image} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="webSite">Web Site</label>
                <input type="text" id="webSite" value={inputState.webSite} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="currency">currency</label>
                <input type="text" id="currency" value={inputState.currency} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="price">price</label>
                <input type="number" id="price" value={inputState.price} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="evrCurrency">evrCurrency</label>
                <input type="text" id="evrCurrency" value={inputState.evrCurrency} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="priceEvrCurrency">evr price</label>
                <input type="number" id="priceEvrCurrency" value={inputState.priceEvrCurrency} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="priceEvrCurrency">Coupon Code</label>
                <input type="text" id="codeCoupon" value={inputState.codeCoupon} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="available">available</label>
                <input type="number" id="available" value={inputState.available} onChange={handleChange} />
              </InputField>

              <InputField>
                <label htmlFor="description">Description</label>
                <NavButton onClick={handleAddNewSection}>Add new Section</NavButton>
              </InputField>

              {bonusDetailsState.bonusDetails!.description?.map((val, index) => {
                return <EditorInstance value={val} key={val + index} state={inputState} setState={setInputState} index={index} />;
              })}
              <FlexRowSpacedAligned>
                <NavButton onClick={handleSaveClick}>save</NavButton>
                <NavButton onClick={handleResetClick}>reset</NavButton>
              </FlexRowSpacedAligned>
            </>
          ) : null}
        </EditBonusCont>
        {checkValidity() ? (
          <BonusPreviewCont>
            <>
              <BonusPreview>
                {!bonusDetailsState.loading && bonusDetailsState.bonusDetails ? (
                  <BonusDescriptionPreviewCont>
                    {"Card Preview"}
                    <Card card={bonusDetailsState.bonusDetails} />
                  </BonusDescriptionPreviewCont>
                ) : null}
              </BonusPreview>
              {bonusDetailsState.bonusDetails && bonusDetailsState.bonusDetails.description ? (
                <BonusDescriptionPreviewCont>
                  {"Description preview"}
                  {bonusDetailsState.bonusDetails.description.map((value, index) => {
                    const clean = sanitizeHtml(value, {
                      allowedTags: ["ul", "li", "p", "strong"],
                      allowedAttributes: { p: ["style"], li: ["style"], ul: ["style"] },
                    });
                    return (
                      <React.Fragment key={value + index}>
                        <BonusParagraph varient="mainText" dangerouslySetInnerHTML={{ __html: clean }}></BonusParagraph>
                      </React.Fragment>
                    );
                  })}
                </BonusDescriptionPreviewCont>
              ) : null}
            </>
          </BonusPreviewCont>
        ) : null}
        {bonusDetailsState.error ? <Typography varient="subtitle1">{bonusDetailsState.error.message}</Typography> : null}
        {bonusDetailsState.loading ? <VanillaSpinner /> : null}
      </EditBonusPageContainer>
    </>
  );
}
