import React, { CSSProperties } from "react";
import { SpinnerCircular } from "spinners-react";
import { useTheme } from "styled-components";
import styled from "styled-components";
interface ISpinnerProps {
  style?: CSSProperties;
  loading?: boolean;
}
const Spinner = (props: ISpinnerProps) => {
  const theme = useTheme();
  return <SpinnerCircular enabled={props.loading} style={{ color: theme.palette.primary.main, ...props.style }} />;
};

const SpinnerStyle = styled.div`
  width: 100px;
  height: 100px;
  border: 5px solid gray;
  border-radius: 100%;
  animation: animate1 2s linear 0s infinite;
  border-top: 5px solid greenyellow;
  margin: auto;
  @keyframes animate1 {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerCont = styled.div`
  position: absolute;
  background-color: #d3d3d38d;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
`;
export const VanillaSpinner = () => {
  return (
    <SpinnerCont>
      <SpinnerStyle />
    </SpinnerCont>
  );
};
export default Spinner;
