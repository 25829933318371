import styled from "styled-components";
import PageLayout from "./PageLayout";

const MainAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const MainAppLayout = ({ children }: { children: any }) => {
  return (
    <>
      <MainAppContainer>
        <PageLayout>{children}</PageLayout>
      </MainAppContainer>
    </>
  );
};

export default MainAppLayout;
