import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { IBonus } from "../../../interfaces/models/bonus";
import BonusApi from "../../api/bonus-api";
import { createBonus, createBonusFail, createBonusSuccess, fetchByIdFail, fetchByIdSuccess, getBonusById, updateBonus } from "./bonusDetails-slice";

function* _getBonusById(action: PayloadAction<{ bonusId: string }>): any {
  const { bonusId } = action.payload;
  const { data, status, error } = yield call(BonusApi.getBonusById, bonusId);
  try {
    if (error) {
      yield put(fetchByIdFail(error));
    }
    if (status === 200) {
      yield put(fetchByIdSuccess(data));
    }
  } catch (err: any) {
    return yield put(fetchByIdFail(err));
  }
}

function* _updateBonus(action: PayloadAction<{ bonus: IBonus }>): any {
  const bonus = action.payload;

  const { data, status, error } = yield call(BonusApi.updateBonusById, bonus);

  try {
    if (error) {
      yield put(fetchByIdFail(error));
    }
    if (status === 200) {
      yield put(fetchByIdSuccess(data));
    }
  } catch (err: any) {
    return yield put(fetchByIdFail(err));
  }
}

function* _createBonus(action: PayloadAction<{ bonus: IBonus }>): any {
  const bonus = action.payload;

  const { data, status, error } = yield call(BonusApi.createBonus, bonus);

  try {
    if (error) {
      yield put(createBonusFail(error));
    }
    if (status === 200) {
      yield put(createBonusSuccess(data));
    }
  } catch (err: any) {
    return yield put(createBonusFail(err));
  }
}

function* bonusDetailsSaga() {
  yield takeLatest(getBonusById.type, _getBonusById);
  yield takeLatest(updateBonus.type, _updateBonus);
  yield takeLatest(createBonus.type, _createBonus);
}

export default bonusDetailsSaga;
