import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IError } from "../../../interfaces/base";
import { ILoginForm } from "../../../interfaces/components/forms/login";
import { IBonus } from "../../../interfaces/models/bonus";
import { IBonusState } from "../../../interfaces/store/bonus-interface";

const initialState: IBonusState = {
  bonusesArray: [],
  metadata: { page: 0, totalItems: 0, limit: 0 },
  loading: true,
  error: null,
};

const _loadBonuses = (state: IBonusState, { payload }: PayloadAction<{}>) => {
  state.bonusesArray = [];
  state.loading = true;
  state.error = null;
};

const _fetchPageSuccess = (
  state: IBonusState,
  {
    payload,
  }: PayloadAction<{
    bonus: IBonus[];
  }>
) => {
  state.bonusesArray = payload.bonus;
  state.loading = false;
  state.error = null;
};

const _fetchFail = (state: IBonusState, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusesArray = [];
  state.metadata.page = 0;
  state.metadata.totalItems = 0;
  state.metadata.limit = 0;
  state.loading = false;
  state.error = payload;
};

const _deleteBonus = (state: IBonusState, { payload }: PayloadAction<{ id: string; index: number }>) => {
  state.loading = true;
  state.error = null;
};

const _deleteBonusSuccess = (state: IBonusState, { payload }: PayloadAction<{ index: number }>) => {
  state.bonusesArray!.splice(payload.index, 1);
  state.error = null;
  state.loading = false;
};
const _deleteBonusError = (state: IBonusState, { payload }: PayloadAction<{ message: string }>) => {
  state.bonusesArray = [];
  state.metadata.page = 0;
  state.metadata.totalItems = 0;
  state.metadata.limit = 0;
  state.loading = false;
  state.error = payload;
};

const BonusSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    fetchFail: _fetchFail,
    loadBonuses: _loadBonuses,
    fetchPageSuccess: _fetchPageSuccess,
    deleteBonus: _deleteBonus,
    deleteBonusSuccess: _deleteBonusSuccess,
    deleteBonusError: _deleteBonusError,
  },
});

const { actions, reducer } = BonusSlice;
export const { fetchFail, loadBonuses, fetchPageSuccess, deleteBonus, deleteBonusSuccess, deleteBonusError } = actions;

export default reducer;
