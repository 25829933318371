import styled from "styled-components";
import { FlexColumn, FlexRowCentered, FlexRowSpaced } from "../common/containers";
import { DashedBorder, PriceText } from "../home/card/CardStyle";
import { Typography } from "../ui/Typography";

export const BonusPageContainer = styled.div`
  margin: auto;
  margin-top: 87px;
  margin-bottom: 112px;
  width: 100%;
  max-width: 800px;
`;
export const BonusPageDetailsContainer = styled.div`
  height: calc(100vh - 187px);
  overflow: scroll;
`;
export const PageBounsImg = styled.img`
  width: 100%;
  min-height: 180px;
  max-height: 300px;
  margin-bottom: 20px;
  @media screen and (min-width: 600px) {
    max-height: 400px;
  }
`;

export const PageBonusCardTitle = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  text-align: right;
`;
export const ShortDescriptionDisplay = styled(Typography)`
  font-weight: 600;
  font-size: 13px;
  text-align: right;
  padding: 20px;
`;
export const PageLogo = styled.img`
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  background-color: lightgray;
  border-radius: 100%;
  margin-right: 20px;
  margin-left: 10px;
`;
export const PagePriceText = styled(Typography)`
  justify-content: flex-end;
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0px 20px 3px;
`;
export const HeaderContainer = styled(FlexColumn)`
  width: 100%;
`;
export const PageBonusSubHeader = styled(FlexRowSpaced)`
  svg {
    margin: 0px 10px;
  }
`;
export const BonusPageButtonContainer = styled(FlexRowCentered)`
  box-sizing: border-box;
  width: 100%;
  button {
    margin: 20px 15px;
  }
  background-color: white;
  /* @media screen and (max-width: 450px) {
    button {
      margin: 15px;
    }
  } */
`;
export const BonusParagraph = styled(Typography)`
  text-align: right;
  font-size: 16px;
  border-bottom: 1px dashed #c4c4c4;
  margin-bottom: 15px;
  margin-inline: auto;
  width: calc(100% - 15px);
  padding: 10px;
  box-sizing: border-box;
  direction: rtl;
  &:last-of-type {
    border: none;
  }
`;
export const ItemDescription = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  padding: 10px 20px;
`;
export const BonusPageFooter = styled(FlexColumn)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const BonusPageFooterInformation = styled.div`
  background-color: #f9f1ff;
  text-align: right;
`;
