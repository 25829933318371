import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { Button } from "../../ui/Buttons";
import {
  DisclaimerText,
  Popup,
  PopupBtnContainer,
  PopupContainer,
  PopupHeadline,
  PopupImg,
  WarningText,
} from "./confirmBonusStyle";

interface IPopupProps {
  togglePopup: () => void;
}

export const ConfirmBonus: React.FC<IPopupProps> = ({ togglePopup }) => {
  const bonusDetailsState = useAppSelector((state) => state.bonusDetails);
  const { t } = useTranslation();

  return (
    <PopupContainer
      onClick={() => {
        togglePopup();
      }}
    >
      <Popup>
        <PopupHeadline varient='h4'>
          {bonusDetailsState.bonusDetails?.title}
        </PopupHeadline>
        <PopupImg
          src={bonusDetailsState.bonusDetails?.image}
          alt='img'
        ></PopupImg>
        <DisclaimerText>{t("bonusRedeem.disclaimer")}</DisclaimerText>
        <WarningText>{t("bonusRedeem.warning")}</WarningText>

        <PopupBtnContainer>
          <Button varient='secondery' width={131} height={40}>
            {t("bonusRedeem.cancel")}
          </Button>
          <Button width={210} height={40}>
            {t("bonusRedeem.use")}
          </Button>
        </PopupBtnContainer>
      </Popup>
    </PopupContainer>
  );
};
export default ConfirmBonus;
