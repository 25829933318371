import styled from "styled-components";
import { Typography } from "../../ui/Typography";

import {
  FlexColumnCentered,
  FlexRowCentered,
  FlexRowSpaced,
} from "../../common/containers";
import { Button } from "../../ui/Buttons";
export const CardContainer = styled(FlexColumnCentered)`
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 2px rgba(29, 39, 37, 0.06);
  border-radius: 16px;
  margin: 30px auto;
  max-width: 400px;
  height: 245px;
`;
export const CardImgContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 130px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;
export const CardBonusImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: 35vh;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;
export const BonusPrice = styled(FlexRowCentered)`
  border-radius: 5px;

  @media screen and (max-width: 600px) {
    right: 55%;
  }
`;
export const PriceText = styled(Typography)`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
  padding-left: 5px;
`;
export const BonusCardTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: right;
  width: 100%;
`;
export const BonusCardText = styled(Typography)`
  width: 100%;
  font-size: 13px;
  line-height: 22px;
  text-align: right;
  font-weight: 300;
`;
export const BottomContent = styled(FlexRowSpaced)`
  align-items: flex-end;
  width: 100%;
`;
export const CompanyName = styled(Typography)`
  font-weight: 600;
`;
export const CompanyLogo = styled.img`
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: -30px;
  left: 15px;
  background-color: #3262a8;
  object-fit: contain;
  object-position: center;
`;
export const DashedBorder = styled.div`
  border: 1px dashed #c4c4c4;
  width: 100%;
`;
export const CardButtonContiner = styled.div`
  padding: 15px;
`;
