import React from "react";
import { FlexRowCentered } from "../common/containers";
import LoginForm from "./LoginForm";
const Login = () => {
  window.React = React;

  return (
    <FlexRowCentered>
      <LoginForm with2fa={false} />
    </FlexRowCentered>
  );
};

export default Login;
