const auth = {
  userToken: "/api/v1/auth/token",
  verifyCode: "/api/v1/auth/code",
  resendCode: "/api/v1/auth/code/resend",
  userinfo: "/api/v1/auth/userinfo",
  logout: "/api/v1/auth/logout",
  updatePassword: "/api/v1/auth/updatePassword",
  forgotPassord: "/api/v1/auth/forgot",
};

const files = {
  files: "/api/v1/files",
  presignedUrl: "/api/v1/files/presigned-url",
};

const bonus = {
  getAll: "/api/v1/bonus",
  getAllNoPagination: "/api/v1/bonus/all",
  getWithId: "/api/v1/bonus/",
  updateBonus: "/api/v1/bonus/update",
  createBonus: "/api/v1/bonus/create",
  deleteBonus: "/api/v1/bonus/delete/",
};

const ServerRoutes = {
  auth,
  files,
  bonus,
};

export default ServerRoutes;
