import React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useEmailValidator, usePasswordValidator } from "../../hooks/validators/useRegExValidator";
import { useForm } from "../../hooks/useForm";
import { Button } from "../ui/Buttons";
import { Typography } from "../ui/Typography";
import { ILoginForm } from "../../interfaces/components/forms/login";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

import FormInput from "../ui/inputs/FormInput";
import Spinner from "../ui/Spinner";
import { Buttoncontainer, Container, LoginWith, Spinnercontainer, StyledBreakLine, StyledLoginText } from "./LoginStyles";
import env from "../../config";
import { loginStart } from "../../store/features/auth-user/auth-user-slice";

const LoginView = (props: { onLoginSubmit: any; setView: any }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { onLoginSubmit, setView } = props;
  const auth = useAppSelector((state) => state.auth);
  const {
    handleSubmit,
    handleChange,
    data: loginState,
    errors,
  } = useForm<ILoginForm>({
    validations: {
      email: {
        custom: {
          isValid: () => emailValidation,
          message: "כתובת אימייל לא תקינה",
        },
      },
      password: {
        custom: {
          isValid: () => passwordValidation,
          message: "הסיסמה צריכה להכיל אות גדולה, אות קטנה, מספר ותו מיוחד",
        },
      },
    },
    onSubmit: () => {
      if (onLoginSubmit) {
        onLoginSubmit(loginState);
      }
    },
  });
  const emailValidation = useEmailValidator(loginState.email);
  const passwordValidation = usePasswordValidator(loginState.password);

  const onLoginClick = () => {
    handleSubmit();
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <FormInput value={loginState?.email || ""} placeholder={t("login.email")} onChange={handleChange("email")} varient={"underline"} errorMessage={errors.email} withShowingError={true} />
      <FormInput
        value={loginState.password || ""}
        placeholder={t("login.pass")}
        onChange={handleChange("password")}
        varient={"underline"}
        type={"password"}
        errorMessage={errors.password}
        withShowingError={true}
      />

      <Buttoncontainer>
        <Button width={80} varient="primary" size="small" onClick={onLoginClick}>
          {t("login.login")}
        </Button>
        {auth.error ? (
          <Typography
            onClick={() => {
              setView("reset");
            }}
            varient="subtitle1"
            color="error"
            style={{ margin: "16px 0", textAlign: "center" }}
          >
            {auth.error.message}
          </Typography>
        ) : null}
      </Buttoncontainer>
      <Spinnercontainer>
        <Spinner loading={auth.loading} />
      </Spinnercontainer>
    </Container>
  );
};

const LoginForm = (props: { with2fa?: boolean; loginAction?: (data: ILoginForm) => any; verifyCodeAction?: any; resendCodeAction?: any; resetPasswordAction?: any }) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [view, setView] = useState<"login" | "2fa" | "reset">("login");
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.user) {
      navigate("/home");
    }
  }, [auth]);

  const onLoginSubmit = (data: ILoginForm) => {
    const { email, password } = data;
    dispatch(
      loginStart({
        email,
        password,
      })
    );
  };

  return <LoginView onLoginSubmit={onLoginSubmit} setView={setView} />;
};

export default LoginForm;
