import styled from "styled-components";
import { FlexRowSpaced } from "../common/containers";
import Spinner, { VanillaSpinner } from "../ui/Spinner";
import { Typography } from "../ui/Typography";

export const NavCont = styled(FlexRowSpaced)`
  align-items: center;
`;

export const ReactTableCont = styled.div`
  width: 100vw;
  overflow: scroll;
`;
export const NotFoundMessage = styled(Typography)`
  text-align: center;
  margin-top: 50px;
`;
export const NavButton = styled.button`
  display: block;
  border-radius: 10px;
  margin: 10px;
  background-color: #96b8ff;
  border: none;
  padding: 10px;
`;

export const SuccessMessage = styled(Typography)`
  background-color: lightgreen;
  padding: 20px;
  border-radius: 20px;
  width: 50%;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
`;
export const FlexRowSpacedAligned = styled(FlexRowSpaced)`
  align-items: baseline;
`;
