import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

import { FlexRowSpaced } from "../common/containers";
import { ReactComponent as GemsIcon } from "../../assets/icons/diamond-icon-small.svg";
import { ReactComponent as Icon } from "../../assets/icons/bonus-page-icon.svg";
import {
  BonusPageContainer,
  PageBounsImg,
  PageBonusCardTitle,
  PageLogo,
  PagePriceText,
  HeaderContainer,
  PageBonusSubHeader,
  BonusPageButtonContainer,
  BonusParagraph,
  ShortDescriptionDisplay,
  ItemDescription,
  BonusPageFooter,
  BonusPageFooterInformation,
  BonusPageDetailsContainer,
} from "./bonusPageStyle";
import { Button } from "../ui/Buttons";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Spinner from "../ui/Spinner";
import { NotFoundMessage } from "../home/HomeStyles";
import ConfirmBonus from "./confirmBonusPopup/ConfirmBonus";
import { getBonusById } from "../../store/features/bonusDetails/bonusDetails-slice";

export default function BonusPage() {
  const dispatch = useAppDispatch();
  const bonusDetailsState = useAppSelector((state) => state.bonusDetails);

  const { t } = useTranslation();
  const { id } = useParams();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    dispatch(getBonusById({ bonusId: id }));
  }, []);

  const togglePopup = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  const mapDescription = () => {
    if (bonusDetailsState.bonusDetails && bonusDetailsState.bonusDetails.description) {
      return (
        <>
          {bonusDetailsState.bonusDetails.description.map((value, index) => {
            const clean = sanitizeHtml(value, {
              allowedTags: ["ul", "li", "p", "strong"],
              allowedAttributes: { p: ["style"], li: ["style"], ul: ["style"] },
            });
            return (
              <React.Fragment key={value + index}>
                <BonusParagraph varient="mainText" dangerouslySetInnerHTML={{ __html: clean }}></BonusParagraph>
              </React.Fragment>
            );
          })}
        </>
      );
    } else return null;
  };

  return (
    <BonusPageContainer>
      {bonusDetailsState.loading ? (
        <Spinner />
      ) : bonusDetailsState.bonusDetails ? (
        <>
          <BonusPageDetailsContainer>
            <PageBounsImg src={bonusDetailsState.bonusDetails.image} alt="img" />
            <FlexRowSpaced>
              <HeaderContainer>
                <PageBonusCardTitle>{bonusDetailsState.bonusDetails.title}</PageBonusCardTitle>
                <PageBonusSubHeader>
                  <Icon />
                  <PagePriceText varient="body2">
                    {bonusDetailsState.bonusDetails.evrCurrency === "gems" ? <GemsIcon /> : null}
                    {bonusDetailsState.bonusDetails.priceEvrCurrency} +{bonusDetailsState.bonusDetails.currency}
                    {bonusDetailsState.bonusDetails.price}
                  </PagePriceText>
                </PageBonusSubHeader>
              </HeaderContainer>
              <PageLogo src={bonusDetailsState.bonusDetails.logo} />
            </FlexRowSpaced>
            <ShortDescriptionDisplay>{bonusDetailsState.bonusDetails.shortDescription}</ShortDescriptionDisplay>
            <ItemDescription>{t("bonusPage.itemDescription")}</ItemDescription>
            {mapDescription()}
          </BonusPageDetailsContainer>
          <BonusPageFooter>
            <BonusPageFooterInformation>כרגע מסתכלים על הטבה זאת {Math.floor(Math.random() * 10 + 1)}</BonusPageFooterInformation>
            <BonusPageButtonContainer>
              <Button varient="secondery" width={131} height={40}>
                <a href={bonusDetailsState.bonusDetails.webSite} target="_blank" style={{ color: "black", textDecoration: "none" }}>
                  {t("bonusPage.toSite")}
                </a>
              </Button>
              <Button width={210} height={40} onClick={togglePopup}>
                {t("bonusPage.use")}
              </Button>
            </BonusPageButtonContainer>
          </BonusPageFooter>
        </>
      ) : null}

      {bonusDetailsState.error ? <NotFoundMessage varient="h1">{t("bonusPage.notFound")}</NotFoundMessage> : null}
      {isPopUpOpen ? <ConfirmBonus togglePopup={togglePopup} /> : null}
    </BonusPageContainer>
  );
}
