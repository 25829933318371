import React, { useEffect, useState } from "react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, SortingState, getSortedRowModel } from "@tanstack/react-table";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteBonus, loadBonuses } from "../../store/features/bonus/bonus-slice";
import { NavButton, NavCont, ReactTableCont, SuccessMessage } from "./HomeStyles";
import { CheckBoxCont, ReactTable, TableBody, TableCell, TableFoot, TableHeader, TableHeaderCell, TableRow } from "./TableStyle";
import { IBonus } from "../../interfaces/models/bonus";

import { ReactComponent as EVRIcon } from "../../assets/icons/nav-evr.svg";
import { toggleBonusCreated } from "../../store/features/bonusDetails/bonusDetails-slice";
import { VanillaSpinner } from "../ui/Spinner";
import { Column } from "react-table";

const columnHelper = createColumnHelper<IBonus>();

const Home = () => {
  const bonusState = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [displayCreatedMessage, setDisplayCreatedMessage] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    if (bonusState.bonusDetails.bonusCreated) {
      setDisplayCreatedMessage(true);
      dispatch(toggleBonusCreated());
    } else {
      setDisplayCreatedMessage(false);
    }
    dispatch(loadBonuses({}));
  }, []);

  const columns = [
    columnHelper.accessor("title", {
      header: () => "Title",
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("shortDescription", {
      header: "Short Description",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("image", {
      header: () => "Image source",
      footer: (info) => info.column.id,
      cell: (info)=> {
        return (
          <img alt="img" src={info.getValue()} width={100}/>
        )
      }
    }),
    columnHelper.accessor("logo", {
      header: "Compony logo",
      footer: (info) => info.column.id,
      cell: (info)=> {
        return (
          <img alt="img" src={info.getValue()} width={40}/>
        )
      }
    }),
    columnHelper.accessor("description", {
      header: "Description",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("sponsorName", {
      header: "Sponsor Name",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("webSite", {
      header: "Web site",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("evrCurrency", {
      header: "EVR Currency type",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("priceEvrCurrency", {
      header: "EVR Currency Price",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("currency", {
      header: "Currency",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("price", {
      header: "Price",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("available", {
      header: "available",
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (props) => {
        return (
          <Menu menuButton={<MenuButton>...</MenuButton>}>
            <MenuItem
              onClick={() => {
                navigate(`/edit/${props.row.original._id}`);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(deleteBonus({ id: props.row.original._id!, index: props.row.index }));
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: bonusState.bonus.bonusesArray!,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility: {
        shortDescription: false,
        description: false,
        webSite: false,
        sponsorName: false,
        updatedAt: false,       
        createdAt: false,       
        codeCoupon: false,
      }
    }
  });

  const handleAddNewBonusClick = () => {
    navigate(`/edit/new`);
  };

  const handleRefreshClick = () => {
    dispatch(loadBonuses({}));
  };

  const isColumnVisible = (column:Column<IBonus>):boolean => {
    if (column.Header === 'Title') {
      return true;
    }
    if (column.Header === 'Short Description') {
      return true;
    }
    if (column.Header === 'Actions') {
      return true;
    }
    if (column.Header === 'EVR Currency type') {
      return true;
    }
    if (column.Header === 'EVR Currency Price') {
      return true;
    }
    if (column.Header === 'Currency') {
      return true;
    }
    if (column.Header === 'Price') {
      return true;
    }
    if (column.Header === 'available') {
      return true;
    }
    
    return false;
  }

  return (
    <>
      <NavCont>
        <EVRIcon />
        <NavButton onClick={handleAddNewBonusClick}>Add new bonus</NavButton>
        <NavButton onClick={handleRefreshClick}>Refresh</NavButton>
      </NavCont>
      <CheckBoxCont>
        <div>
          <label>
            <input
              {...{
                type: "checkbox",
                checked: table.getIsAllColumnsVisible(),
                onChange: table.getToggleAllColumnsVisibilityHandler(),
              }}
            />
            Toggle All
          </label>
        </div>
        {table.getAllLeafColumns().map((column) => {
          return (
            <div key={column.id}>
              <label>
                <input
                  {...{
                    type: "checkbox",
                    checked: column.getIsVisible(),
                    onChange: column.getToggleVisibilityHandler(),
                  }}
                />{" "}
                {column.id}
              </label>
            </div>
          );
        })}
      </CheckBoxCont>
      {displayCreatedMessage ? <SuccessMessage varient="h2">Created new Bonus</SuccessMessage> : null}

      <ReactTableCont>
        <ReactTable
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHeaderCell key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                            onClick: header.column.getToggleSortingHandler(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </TableHeaderCell>
                  );
                })}
              </tr>
            ))}
          </thead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </TableFoot>
        </ReactTable>
      </ReactTableCont>
      {bonusState.bonus.loading ? <VanillaSpinner /> : null}
    </>
  );
};

export default Home;
