import styled from "styled-components";
import { FlexRowSpaced } from "../../common/containers";
import { Typography } from "../../ui/Typography";

export const PopupContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  background: rgba(217, 217, 217, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Popup = styled.div`
  padding: 30px;
  box-sizing: border-box;
  max-width: 376px;
  width: 100%;
  height: 477px;
  background: #ffffff;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
`;
export const PopupHeadline = styled(Typography)`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
`;
export const PopupImg = styled.img`
  max-width: 313px;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
  margin: auto;
`;
export const DisclaimerText = styled(Typography)`
  font-weight: 300;
  font-size: 14px;
  text-align: right;
  max-width: 340px;
  margin: none;
`;
export const WarningText = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  color: #ff0000;
  text-align: right;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
`;
export const PopupBtnContainer = styled(FlexRowSpaced)`
  box-sizing: border-box;
  width: 100%;
  /* margin: 25px; */
  button {
    margin: 10px;
  }
`;
