import styled from "styled-components";
import { FlexColumn, FlexColumnCentered, FlexRow, FlexRowSpaced } from "../common/containers";
import { Typography } from "../ui/Typography";
export const EditBonusCont = styled(FlexColumnCentered)`
  margin: 30px;
  border: 0.5px solid lightgray;

  border-radius: 20px;
  padding: 10px;
`;
export const BonusPreview = styled.div`
  width: 100%;
`;

export const InputField = styled(FlexRowSpaced)`
  width: 100%;
  padding: 10px;
  &:hover {
    background-color: lightgoldenrodyellow;
    border-radius: 10px;
  }
  input {
    width: 250px;
  }
`;
export const EditBonusPageContainer = styled(FlexRow)`
  justify-content: space-evenly;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const BonusParagraph = styled(Typography)`
  text-align: right;
  font-size: 16px;
  border-bottom: 1px dashed #c4c4c4;
  margin-bottom: 15px;
  margin-inline: auto;
  width: calc(100% - 15px);
  padding: 10px;
  direction: rtl;
  box-sizing: border-box;
  &:last-of-type {
    border: none;
  }
`;

export const BonusPreviewCont = styled(FlexColumn)`
  flex-grow: 1;
  margin: 30px;
`;

export const BonusDescriptionPreviewCont = styled.div`
  border: 0.5px solid lightgray;
  border-radius: 20px;
  padding: 10px;
  margin: 20px 0;
`;
